import { CancelOutlined } from '@mui/icons-material';
import { Button, CircularProgress, Paper, Typography } from '@mui/material';
import { navigate } from 'gatsby';

import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import { isIOS, isMobile } from 'react-device-detect';
import { connect } from 'react-redux';
import { AppDownloadUrl } from '../pageConstants';
import * as styles from './lan-success.module.css';
export type ModalState = 'init' | 'loading' | 'done';

function Modal(props) {
  const [hasSent, setHasSent] = React.useState<ModalState>('init');
  const onCta = async () => {
    setHasSent('loading');
    if (isMobile) {
      window.location.href = AppDownloadUrl;
      return;
    }
    const headers = new Headers();
    headers.append('Content-Type', 'application/json');
    headers.append(
      'Authorization',
      'Basic YXBpX3VzZXJfdGVzdDpZV1U0TUdJMVptRXRPRGczTlMwME16TXlMV0U1TnpjdFlUUmtZMkptWWpreE1XRms=',
    );
    await fetch('https://card.rockerapi.dev/card/mandarin/invite-sms/send', {
      method: 'post',
      body: JSON.stringify({
        phone: props.loanApplication.phone,
      }),
      headers,
    });
    setHasSent('done');
  };
  const onClose = () => {
    navigate('/');
  };
  return (
    <div className={styles.container}>
      <Paper className={styles.paper}>
        <CancelOutlined
          onClick={onClose}
          style={{
            position: 'absolute',
            color: 'gray',
            cursor: 'pointer',
            top: 24,
            right: 24,
          }}
        />
        <StaticImage
          style={{
            width: 340,
            height: 340,
          }}
          height={340}
          width={340}
          alt="success"
          src="../components/LoanFormCard/successmodal-image.png"
        />
        <Typography variant="h1">Följ din ansökan i appen!</Typography>
        <Typography textAlign={'center'}>
          Vi har skickat dig en länk på både mail och sms för att ladda ned
          Rocker-appen. Där kan du enkelt följa dina låneerbjudanden.{' '}
        </Typography>
        <Button
          startIcon={hasSent === 'loading' && <CircularProgress size="24px" />}
          disabled={hasSent !== 'init'}
          fullWidth
          onClick={onCta}
          variant="contained"
        >
          {isMobile
            ? `Ladda ner på ${isIOS ? 'App Store' : 'Google Play'}`
            : 'Skicka länken igen'}
        </Button>
      </Paper>
    </div>
  );
}
const mapStateToProps = state => ({
  loanApplication: state.loanApplication,
});

export default connect(mapStateToProps)(Modal);
