export default {
  CreatingApplication: 'CreatingApplication',
  Quotation: 'Quotation',
  BankAccount: 'BankAccount',
  BillOfDebt: 'BillOfDebt',
  NewLoan: 'NewLoan',
  Accepted: 'Accepted',
  Denied: 'Denied',
};

export const ZENDESK_KEY = '4b6f635c-5444-4737-83f1-a276ea65639';
export const AppDownloadUrl = 'https://app.rocker.com/QyXr';
